import React from 'react'

const Baner = ({titulo}) => {
  return (
    <h1 className='Baner'>
        {titulo}
    </h1>
  )
}

export default Baner